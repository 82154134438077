import React, { useState, useEffect } from 'react';
import { Map, Marker, TileLayer } from 'react-leaflet'
import axios from 'axios'
import './App.css';
// Contexts
import {CoordsContext} from './contexts/CoordsContext';
import { PebContext } from './contexts/PebContext';
import { LayersContext } from './contexts/LayersContext'
// Components
import PebComponent from './components/PebComponent';
import HeaderComponent from './components/HeaderComponent';
import FloodsComponent from './components/FloodsComponent';
import EarthquakesComponent from './components/EarthquakesComponent'
import PebZoneAComponent from './components/PebZoneAComponent';
import AleargComponent from './components/AleargComponent'
// Query Geoserver
import {displayGeoData} from './geoQuery'

function App() {
  // Contexts:
  const [peb, setPeb] = useState([])
  const [pebZoneA, setPebZoneA] = useState([])
  const [pebZoneB, setPebZoneB] = useState([])
  const [pebZoneC, setPebZoneC] = useState([])
  const [pebZoneD, setPebZoneD] = useState([])
  const [floods, setFloods] = useState([])
  const [earthquakes, setEarthquakes] = useState([])
  const [alearg, setAlearg] = useState([])
  // Contexts: Control address input flow
  const [gps, setGps] = useState(null)
  const [address, setAddress] = useState('')
  // Locals:
  const [zoom] = useState(12)

  // Extract GPS on a map click
  const setGpsOnClick = e => setGps([e.latlng.lng, e.latlng.lat])
  // Set the address from coords gotten by setGpsOnClick
  useEffect(() => { if (gps !== null) {
    axios
      .get(`https://api-adresse.data.gouv.fr/reverse/?lon=${gps[0]}&lat=${gps[1]}`)
      .then(res => { setAddress(res.data.features[0].properties.label) })
      .catch(err => console.log(err))
    }
  }, [gps])
  // SetFloods according to the user address entered and suggestion clicked
  useEffect(() => {
    displayGeoData('esris', 'inondation', gps, setFloods)
    displayGeoData('esris', 'mouvement_terrain', gps, setEarthquakes)
    displayGeoData('esris', 'peb_aerodrome', gps, setPeb)
    displayGeoData('esris', 'peb_zone_a', gps, setPebZoneA)
    displayGeoData('esris', 'peb_zone_b', gps, setPebZoneB)
    displayGeoData('esris', 'peb_zone_c', gps, setPebZoneC)
    displayGeoData('esris', 'peb_zone_d', gps, setPebZoneD)
    displayGeoData('esris', 'alearg', gps, setAlearg)
  }, [gps])



  return (
    <div className="container">
      <CoordsContext.Provider value={{gps, setGps, address, setAddress}}>
      <LayersContext.Provider 
        value={{
          floods, setFloods, 
          earthquakes, setEarthquakes, 
          pebZoneA, setPebZoneA, pebZoneB, setPebZoneB, pebZoneC, setPebZoneC, pebZoneD, setPebZoneD,
          alearg, setAlearg
        }}>
      <PebContext.Provider value={{peb, setPeb}}>



      <HeaderComponent layers={displayGeoData}/>
      <Map center={gps !== null ? [gps[1], gps[0]] : [48.866667, 2.333333]} zoom={zoom} onclick={setGpsOnClick}>
        {gps !== null ? <Marker position={[gps[1], gps[0]]} /> : ''}
        <TileLayer url='https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoiam9jaDgxNCIsImEiOiJjazdqOW4wdnQwM3FuM2ZxODluM2RydTBtIn0.k_dKU_21hVPO0_xy_QNoyQ' attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a><strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>' />
        <PebComponent />
        <PebZoneAComponent />
        <FloodsComponent />
        <EarthquakesComponent />
        <AleargComponent />
      </Map>
      
      
      
      </PebContext.Provider>
      </LayersContext.Provider>
      </CoordsContext.Provider>
    </div>

  );
}

export default App;

/*
COUNT CUSTOMERS PAID MORE THAN 10€
MariaDB [PROD__API]> select pay_paiements.montant/100, auth_users.email from pay_paiements, auth_users where auth_users.id_auth_users = pay_paiements.id_auth_users and  pay_paiements.montant > 1000  group by auth_users.email;
1906 rows in set (0.041 sec)

COUNT CUSTOMERS PAID MORE LESS 10€
MariaDB [PROD__API]> select pay_paiements.montant/100, auth_users.email from pay_paiements, auth_users where auth_users.id_auth_users = pay_paiements.id_auth_users and  pay_paiements.montant < 1000  group by auth_users.email;
10274 rows in set (0.061 sec)
9588 rows in set (0.059 sec) // GREATER THAN 6€ AND LESS THAN 10€

 */


/*
INFO PLU
https://geo-service.fr/
    geoserver/
    plu/
    ows?service=WFS&
    version=1.0.0&
    request=GetFeature&
    typeName=plu%3Ainfo&
    maxFeatures=50&
    outputFormat=application%2Fjson


INONDATION
https://geo-service.fr/
    geoserver/
    esris/
    ows?service=WFS&
    version=1.0.0&
    request=GetFeature&
    typeName=esris%3Ainondation&
    maxFeatures=50&
    outputFormat=application%2Fjson
INONDATION BY INSEE CODE
https://geo-service.fr/
    geoserver/
    esris/
    ows?service=WFS&
    version=2.0.0&
    request=GetFeature&
    typeNames=esris%3Ainondation&
    count=100&
    outputFormat=application%2Fjson&
    Filter=%3CFilter%3E%3CPropertyIsEqualTo%3E%3CPropertyName%3Einsee_com%3C/
    PropertyName%3E%3CLiteral%3E56240%3C/
    Literal%3E%3C/
    PropertyIsEqualTo%3E%3C/
    Filter%3E
INONDATION BY CITY NAME
https://geo-service.fr/
    geoserver/
    esris/
    ows?service=WFS&
    version=2.0.0&
    request=GetFeature&
    typeNames=esris%3Ainondation&
    count=100&
    outputFormat=application%2Fjson&
    Filter=%3CFilter%3E%3CPropertyIsEqualTo%3E%3CPropertyName%3Enom_com%3C/
    PropertyName%3E%3CLiteral%3EParis%3C/
    Literal%3E%3C/
    PropertyIsEqualTo%3E%3C/
    Filter%3E


PEB
https://geo-service.fr/
    geoserver/
    esris/
    ows?service=WFS&
    version=1.0.0&
    request=GetFeature&
    typeName=esris%3Apeb_aerodrome&
    maxFeatures=50&
    outputFormat=application%2Fjson
*/