import React, { useContext } from 'react'
import { GeoJSONFillable, Patterns} from 'react-leaflet-geojson-patterns';
import { LayersContext } from '../contexts/LayersContext'

export default function FloodsComponent() {
    const { floods } = useContext(LayersContext)
    return (
        <>
         {floods.map(flood => 
          <GeoJSONFillable
            key={flood.id}
            data={flood}
            style={feature => ({
              color: "blue",
              // fillPattern: Patterns.CirclePattern({
              //   x: 7,
              //   y: 7,
              //   radius: 5,
              //   fill: true,
              //   width: 15,
              //   height: 15,
              //   color: "blue",
              //   key: "circle"
              // })
            })}
          />
          )}   
        </>
    )
}
