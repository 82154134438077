import React, { useState, useContext } from 'react'
import { Marker, Popup } from 'react-leaflet'
import { Icon } from 'leaflet'
import {PebContext} from '../contexts/PebContext'

const icon = new Icon({
    iconUrl: '/peb.png',
    iconSize: [25, 25],
})

export default function PebComponent () {
    const { peb } = useContext(PebContext)
    const [ activePeb, setActivePeb ] = useState()
    return (
        <>
            {/* Display all marker on map */}
            {peb.map(point => <Marker 
                key={point.id} 
                icon={icon}
                position={[point.geometry.coordinates[1], point.geometry.coordinates[0]]} 
                onclick={() => setActivePeb(point)}
            />)}

            {/* Show popup info on click */}
            {activePeb && (
            <Popup
                position={[activePeb.geometry.coordinates[1], activePeb.geometry.coordinates[0]]}
                onClose={() => setActivePeb()}
            >
                <div>
                    <h3>{activePeb.properties.nom_carto}</h3>
                    <p>{activePeb.properties.oaci}</p>
                </div>
            </Popup>
            )}
        </>
    )
}