import React, { useContext } from 'react'
import { GeoJSONFillable, Patterns} from 'react-leaflet-geojson-patterns';
import { LayersContext } from '../contexts/LayersContext'

export default function FloodsComponent() {
    const { alearg } = useContext(LayersContext)
    return (
        <>
         {alearg.map(argile => 
          <GeoJSONFillable
            key={argile.id}
            data={argile}
            style={feature => ({ color: "#a07d0c" })}
          />
          )}   
        </>
    )
}
