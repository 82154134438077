import React, { useState, useContext } from 'react'
import axios from 'axios'
import { CoordsContext } from '../contexts/CoordsContext'
import { PebContext } from '../contexts/PebContext'
import { LayersContext } from '../contexts/LayersContext'
import { displayGeoData, loadLayer } from './../geoQuery'


export default function HeaderComponent () {
  // Locals: STATES for layers - [UI load and toggle on off the layer]
  const [loadingPeb, setLoadingPeb] = useState(false)
  const [loadingPebZones, setLoadingPebZones] = useState(false)
  const [loadingFloods, setLoadingFloods] = useState(false)
  const [loadingEarthquakes, setLoadingEarthquakes] = useState(false)
  const [loadingAlearg, setLoadingAlearg] = useState(false)
  const [switchPeb, setSwitchPeb] = useState(false)
  const [switchPebZones, setSwitchPebZones] = useState(false)
  const [switchFloods, setSwitchFloods] = useState(false)
  const [switchEarthquakes, setSwitchEarthquakes] = useState(false)
  const [switchAlearg, setSwitchAlearg] = useState(false)
  // Locals: STATES geo location from api-adresse.data.gouv
  const [suggestions, setSuggestions] = useState([])
  const [typing, setTyping] = useState('')
  // Contexts:
  const { gps, setGps, address, setAddress } = useContext(CoordsContext)
  const { peb, setPeb } = useContext(PebContext)
  const { floods, setFloods, earthquakes, setEarthquakes, pebZoneA, setPebZoneA, pebZoneB, setPebZoneB, pebZoneC, setPebZoneC, pebZoneD, setPebZoneD, alearg, setAlearg } = useContext(LayersContext)

  
// Display the all 220 PEB available in DB
  const displayPEB = () => {
    // setLoadingPeb(true)
    // if (gps === null) return setLoadingPeb(false) && setSwitchPeb(false)
    // if (peb.length) {
    //   setPeb([])
    //   setLoadingPeb(false)
    //   setSwitchPeb(false)
    // } else {
    // displayGeoData('esris', 'peb_aerodrome', gps, setPeb, setLoadingPeb, setSwitchPeb)
    // }
    loadLayer('esris', 'peb_aerodrome', gps, peb, setPeb, setLoadingPeb, setSwitchPeb)
  }
  const displayAlearg = () => {loadLayer('esris', 'alearg', gps, alearg, setAlearg, setLoadingAlearg, setSwitchAlearg)}
//   Display the PEB Zone A layer
  const displayPebZones = () => {
      console.log(pebZoneA.length, pebZoneB.length, pebZoneC.length, pebZoneD.length)
      setLoadingPebZones(true)
    if (gps === null) return setLoadingPebZones(false) && setSwitchPebZones(false)
    if (pebZoneA.length || pebZoneB.length || pebZoneC.length || pebZoneD.length) {
        setPebZoneA([])
        setPebZoneB([])
        setPebZoneC([])
        setPebZoneD([])
        setLoadingPebZones(false) 
        setSwitchPebZones(false)
    } else {
        displayGeoData('esris', 'peb_zone_a', gps, setPebZoneA, setLoadingPebZones, setSwitchPebZones)
        displayGeoData('esris', 'peb_zone_b', gps, setPebZoneB, setLoadingPebZones, setSwitchPebZones)
        displayGeoData('esris', 'peb_zone_c', gps, setPebZoneC, setLoadingPebZones, setSwitchPebZones)
        displayGeoData('esris', 'peb_zone_d', gps, setPebZoneD, setLoadingPebZones, setSwitchPebZones)
    }
  }
//   SetFloods from coords state and handle a boolean loader
  const displayFloods = () => {
    // setLoadingFloods(!loadingFloods)
    // if (gps === null) return setLoadingFloods(false) && setSwitchFloods(false)
    // if (floods.length) {
    //     setFloods([])
    //     setLoadingFloods(false) 
    //     setSwitchFloods(false)
    // } else {
    //     displayGeoData('esris', 'inondation', gps, setFloods, setLoadingFloods, setSwitchFloods)
    // }
    loadLayer('esris', 'inondation', gps, floods, setFloods, setLoadingFloods, setSwitchFloods)
  }
  const displayEarthquakes = () => {
    // setLoadingEarthquakes(true)
    // if (gps === null) return setLoadingEarthquakes(false) && setSwitchEarthquakes(false)
    // if (earthquakes.length) {
    //     setEarthquakes([])
    //     setLoadingEarthquakes(false) 
    //     setSwitchEarthquakes(false)
    // } else {
    //     displayGeoData('esris', 'mouvement_terrain', gps, setEarthquakes, setLoadingEarthquakes, setSwitchEarthquakes)
    //     setLoadingFloods(false)
    // }
    loadLayer('esris', 'mouvement_terrain', gps, earthquakes, setEarthquakes, setLoadingEarthquakes, setSwitchEarthquakes)
  }
//   setSuggestions from the address entered by the user - LIMIT = 3
  const inputAddress = e => {
    setAddress(e.target.value)
    e.target.value.length ? setTyping(e.target.value) : setTyping('')
    axios.get(`https://api-adresse.data.gouv.fr/search/?q=${e.target.value}&limit=3`)
        .then(res => {
            const data = res.data.features
            setSuggestions(data)
        }).catch(err => console.log(err))
  }
//   SetCoords from the suggestion clicked, empty all suggestions
  const selector = (result) => {
    if (floods.length > 0) setFloods([])
    setGps(result.geometry.coordinates)
    setAddress(result.properties.label)
    setSuggestions([])
  }

  return (
    <>
      <header>

          <button className={`${loadingPeb ? 'processing' : ''} ${switchPeb ?  'on' : ''}`} onClick={displayPEB}>Airports</button>
          <button className={`${loadingPebZones ? 'processing' : ''} ${switchPebZones ? 'on' : ''}`} onClick={displayPebZones}>PEB Zones</button>
          <button className={`${loadingFloods ? 'processing' : ''} ${switchFloods ? 'on' : ''}`} onClick={displayFloods}>Floods</button>
          <button className={`${loadingEarthquakes ? 'processing' : ''} ${switchEarthquakes ? 'on' : ''}`} onClick={displayEarthquakes}>Earthquakes</button>
          <button className={`${loadingAlearg ? 'processing' : ''} ${switchAlearg ? 'on' : ''}`} onClick={displayAlearg}>Argiles</button>

      </header>
      <div className="search-address">

          <div className="input-address">
            <input id="address" className="input-address--txt" type="text" onChange={inputAddress} value={address} placeholder="Find an address..."/>
            <label htmlFor="address" className="input-address--label">Find an address :</label>
            <i className="fal fa-map-marker-alt"></i>
          </div>
          
          <ul className={`${suggestions.length ? 'card-suggestions' : ''}`}>
            {typing.length && suggestions.length ? 
              suggestions.map(result => {
                return (
                  <li key={result.properties.id} 
                      className="suggestion-item"
                      onClick={() => selector(result)}>
                  {result.properties.label}
                  </li>)
              })
            : 
            ''}
          </ul>

      </div>

    </>
  )
}