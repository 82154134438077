import axios from 'axios'

function geoQuery (geoDB, geoTable, pagination, gps, radius) {
    const AllGeoDB = ['cadastre', 'esris', 'nuisance_sonore', 'numerique', 'plu', 'sup']
    const AllGeoTable = [
        // Cadastre
        'bati', 'commune', 'departement', 'parcelle', 'parcelle_draw', 'parcelle_old', 'route', 'section', 
        // Esris
        'alearg', 'avalanche', 'basias', 'basol_sis', 'carriere_idf', 'icpe', 'incendie', 'inondation', 'mine', 'mouvement_terrain', 'radon', 'seisme', 'technologique', 'titre_minier',
        // Nuisance Sonore
        'peb_aerodrome', 
        'peb_zone_a',
        'peb_zone_b',
        'peb_zone_c',
        'peb_zone_d',
        // Numerique
        '2g',
        '3g',
        '4g',
        'ffth_commune',
        'ffth_immeuble',
        // PLU
        'habillage',
        'habillage_txt',
        'info',
        'information_type',
        'information_typepsc',
        'plui',
        'prescription',
        'prescription_type',
        'reglements',
        'reglements_titre',
        'risques_gaspar',
        'rnu',
        'secteur_cc',
        'zone_urba',
        'zone_urba_destdomi',
        'zone_urba_type',
        // SUP
        'a_iii_assiette',
        'a_iv_assiette',
        'a_ix_assiette',
        'ac_i_assiette',
        'ac_ii_assiette',
        'ac_iii_assiette',
        'el_ix_assiette',
        'pm_i_assiette',
        'pm_i_generateur',
        'pm_ibis_assiette',
        'pm_ii_assiette',
        'pm_iii_assiette',
        'pm_iii_generateur',
        'pm_iv_assiette',
        'sup_index',
        't_v_assiette'
    ]
    if (!AllGeoDB.includes(geoDB) || !AllGeoTable.includes(geoTable)) return new Error(`Unknown or invalid database...`)
    if(pagination >= 15000 || radius >= 30) return new Error('You won\'t get more!')
    // Get by radius from coords
    return `https://geo-service.fr/geoserver/${geoDB}/ows?service=WFS&version=2.0.0&request=GetFeature&typeNames=${geoDB}%3A${geoTable}&count=${pagination}&outputFormat=application%2Fjson&cql_filter=DWITHIN(geom,Point(${gps[1]}%20${gps[0]}),${radius},kilometers)`
}
export function displayGeoData (geoDB, geoTable, gps, setLayer, setLoading, setToggle, pagination = 5000, radius = 10)  {
  // First render don't display any shape
  if (gps !== null) {
    axios({
      method: 'GET',
      // Get by radius from coords
      url: geoQuery(geoDB, geoTable, pagination, gps, radius)
    }).then( res => {
  // Render the shape with setLayer
      const data = res.data.features
      setLayer(data)
      // setLoading(true)
      setToggle(true)
    }).then(() => setLoading(false))
    .catch(err => console.log(err))
    
  }
}

export function loadLayer(DBname, tableName, coordinates, layer, setLayer, setLoading, setSwitch) {
  setLoading(true)
  if (coordinates === null) return setLoading(false) && setSwitch(false)
  if (layer.length) {
    setLayer([])
    setLoading(false)
    setSwitch(false)
  } else {
    displayGeoData(DBname, tableName, coordinates, setLayer, setLoading, setSwitch)
  }
}