import React, { useContext } from 'react'
import { GeoJSONFillable, Patterns} from 'react-leaflet-geojson-patterns';
import { LayersContext } from '../contexts/LayersContext'

export default function FloodsComponent() {
    const { earthquakes } = useContext(LayersContext)
    return (
        <>
         {earthquakes.map(earthquakes => 
          <GeoJSONFillable
            key={earthquakes.id}
            data={earthquakes}
            style={feature => ({
              color: "brown",
              // fillPattern: Patterns.CirclePattern({
              //   x: 7,
              //   y: 7,
              //   radius: 5,
              //   fill: true,
              //   width: 15,
              //   height: 15,
              //   color: "brown",
              //   key: "circle"
              // })
            })}
          />
          )}   
        </>
    )
}
