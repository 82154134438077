import React, { useContext } from 'react'
import { GeoJSONFillable, Patterns} from 'react-leaflet-geojson-patterns';
import { LayersContext } from '../contexts/LayersContext'

export default function PebZoneAComponent() {
    const { pebZoneA, pebZoneB, pebZoneC, pebZoneD } = useContext(LayersContext)
    return (
        <>
         {pebZoneA.map(zoneA => 
          <GeoJSONFillable
            key={zoneA.id}
            data={zoneA}
            style={feature => ({
              color: "blue",
              // fillPattern: Patterns.CirclePattern({
              //   x: 7,
              //   y: 7,
              //   radius: 5,
              //   fill: true,
              //   width: 15,
              //   height: 15,
              //   color: "blue",
              //   key: "circle"
              // })
            })}
          />
          )}
          {pebZoneB.map(zoneB => 
          <GeoJSONFillable
            key={zoneB.id}
            data={zoneB}
            style={feature => ({
              color: "orange",
              // fillPattern: Patterns.CirclePattern({
              //   x: 7,
              //   y: 7,
              //   radius: 5,
              //   fill: true,
              //   width: 15,
              //   height: 15,
              //   color: "blue",
              //   key: "circle"
              // })
            })}
          />
          )}
          {pebZoneC.map(zoneC => 
          <GeoJSONFillable
            key={zoneC.id}
            data={zoneC}
            style={feature => ({
              color: "red",
              // fillPattern: Patterns.CirclePattern({
              //   x: 7,
              //   y: 7,
              //   radius: 5,
              //   fill: true,
              //   width: 15,
              //   height: 15,
              //   color: "blue",
              //   key: "circle"
              // })
            })}
          />
          )}
          {pebZoneD.map(zoneD => 
          <GeoJSONFillable
            key={zoneD.id}
            data={zoneD}
            style={feature => ({
              color: "purple",
              // fillPattern: Patterns.CirclePattern({
              //   x: 7,
              //   y: 7,
              //   radius: 5,
              //   fill: true,
              //   width: 15,
              //   height: 15,
              //   color: "blue",
              //   key: "circle"
              // })
            })}
          />
          )}
        </>
    )
}
